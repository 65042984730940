<template>
    <div style="position: relative; min-height: 449px;">
        <textarea v-model.lazy="content" ref="editor" :id="$data._id" :name="name" :rows="rows" cols="cols" @change="input" style="width: 100%; display: none">{{ value }}</textarea>
    </div>
</template>

<script>
let uuid = 0;
export default {
    props: {
        id: { type: String, default: null },
        name: { type: String, default: undefined },
        rows: { type: String, default: "10" },
        cols: { type: String, default: "100" },
        value: { type: String, default: '' }
    },
    data() {
        return {
            _id: this.$props.id || `ir${uuid}`,
            oEditors: [],
            content: ''
        };
    },
    beforeCreate() {
        uuid += 1;
    },
    mounted(){
        var component = this;

        this.content = this.value;

        nhn.husky.EZCreator.createInIFrame({
            oAppRef: this.oEditors,
            elPlaceHolder: this.$data._id,
            sSkinURI: "/js/smarteditor2/SmartEditor2Skin.html",
            fCreator: "createSEditor2",
            htParams : {
                bUseToolbar : true,				// 툴바 사용 여부 (true:사용/ false:사용하지 않음)
                bUseVerticalResizer : true,		// 입력창 크기 조절바 사용 여부 (true:사용/ false:사용하지 않음)
                bUseModeChanger : true,			// 모드 탭(Editor | HTML | TEXT) 사용 여부 (true:사용/ false:사용하지 않음)
                bSkipXssFilter : true,		// client-side xss filter 무시 여부 (true:사용하지 않음 / 그외:사용)
            },
            fOnAppLoad : () => {
                //기존 저장된 내용의 text 내용을 에디터상에 뿌려주고자 할때 사용
                try{
                    // this.oEditors.getById[this._id].exec("PASTE_HTML", [this.value]);
                    var contentElement = component.oEditors.getById[component.$data._id].elEditingAreaContainer.children[0].contentWindow.document.getElementsByClassName("se2_inputarea")[0];
                    if(contentElement){
                        contentElement.addEventListener('focusout', function(){
                            component.oEditors.getById[component.$data._id].exec("UPDATE_CONTENTS_FIELD", []);

                            var event = document.createEvent("HTMLEvents");
                            event.initEvent("change", false, true);
                            component.$refs.editor.dispatchEvent(event);
                        });
                    }
                }
                catch(error){
                    console.error("[SmartEditor]-fOnAppLoad");
                    console.error(error);
                }
            }
        });
    },
    methods: {
        input(){
            console.log('input');
            this.$emit('input', this.content);
        }
    },
    watch: {
        value(){
            this.content = this.value;
        }
    }
}
</script>
